html {
    min-width: 320px;
    height: 100%;
}
body {
    min-height: 100%;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
}

*:focus {
    outline: none !important;
}

a {
    text-decoration: none;
    color: #5e01c6;
}

img {
    max-width: 100%;
    display: block;
}

.img {
    &_rounded {
        border-radius: 16px;
    }
}

.block {
    margin-top: 80px;

    &:first-child {
        margin-top: 40px;
    }
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.auth-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &__content {
        display: flex;
        flex: 1;
        align-items: center;
        background: url('./img/background.jpg') no-repeat 50% / cover;

        &_bg2 {
            background: url('./img/background_2.jpg') no-repeat 50% / cover;
        }
    }
}

.auth-block {
    max-width: 448px;
    width: 100%;

    &.MuiPaper-rounded {
        border-radius: 8px;
    }
}

.MuiButton-smallText {
    text-transform: lowercase;
}

.slick-slider {
    margin-bottom: 44px;

    &__visible {
        .slick-list {
            overflow: visible;
        }
    }

    .slick-track {
        margin-left: 0;
    }

    .slick-dots {
        bottom: -32px;

        li {
            width: auto;
            height: auto;

            button {
                width: 12px;
                height: 12px;
                padding: 0;

                &::before {
                    font-size: 12px;
                    line-height: 12px;
                    color: #5e01c6;
                    width: 12px;
                    height: 12px;
                    opacity: 0.35;
                }
                &:hover::before {
                    opacity: 0.6;
                }
            }
            &.slick-active {
                button::before {
                    opacity: 1;
                }
            }
        }

        &_complex {
            @media (min-width: 768px) {
                bottom: -86px;
            }
        }
    }
    &__complexes {
        .slick-dots {
            bottom: -10px;
        }
    }

    .slick-slide {
        opacity: 0.4;
        transition: all 0.5s ease;
    }

    .slick-active {
        opacity: 1;
    }
}
.slick-card .slick-initialized .slick-track {
    display: flex;
    align-items: center;
}

.complex-page {
    width: 100%;
    display: flex;
    flex: 1;
    background: url('./img/complex_bg_1.png') no-repeat 0% 100%, url('./img/complex_bg_2.png') no-repeat 100% 0%,
        url('./img/complex_bg_3.png') no-repeat 100% 30%;
}

.pageNavLink {
    cursor: pointer;
}

.MuiPickersDesktopDateRangeCalendar-dateRangeContainer {
    .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
        &::before {
            content: none !important;
        }
    }
    .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:nth-child(2) {
        display: none;
    }
    .MuiPickersDesktopDateRangeCalendar-arrowSwitcher {
        .MuiPickersArrowSwitcher-hidden {
            visibility: visible;
        }
    }
}

// infinite-scroll
//.infinite-scroll-component {
//    //overflow: unset !important;
//    overflow-y: scroll
//}
.infinite-scroll--x-hidden {
    overflow-x: hidden !important;
}

.infinite-scroll-component__outerdiv {
}

// Autocomplete chips
.MuiAutocomplete-root {
    .MuiAutocomplete-inputRoot:not(.Mui-focused) {
        .MuiChip-label {
            width: 120px;
        }
    }
}

.MuiInputLabel-shrink {
    transform: translate(14px, 6px) scale(0.75) !important;
}
.ReactVirtualized__Grid {
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #ebe0f7;
        border-radius: 8px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ac85e0;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:vertical {
        background: #5e01c6;
        @media (max-width: 768px) {
            background: #ac85e0;
        }
    }
}

.folders {
    &::-webkit-scrollbar {
        width: 4px;
        background-color: #ebe0f7;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ac85e0;
        border-radius: 2px;
    }
}

.infinite-scroll-component {
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #ebe0f7;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ac85e0;
        border-radius: 8px;
    }
}

.placemark_layout_container {
    position: relative;
    font-family: Georgia;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
}

/* Квадратный макет метки */
.square_layout {
    position: absolute;
    left: -23px;
    top: -23px;
    width: 46px;
    height: 46px;
    line-height: 46px;
    border: 2px solid #218703;
    background-color: #f8fdf7;
    color: #218703;
}

.placemark-container {
    width: fit-content;
    white-space: nowrap;
    background: #fff;
    padding: 8px;
    box-shadow: 0px 27px 34px rgba(94, 1, 198, 0.07), 0px 14px 9px rgba(94, 1, 198, 0.0402488), 0px 8px 4px rgba(0, 0, 0, 0.03);
    border: 1px solid #ebe0f7;
    border-radius: 8px 8px 8px 0px;

    &:hover {
        cursor: pointer;
    }

    &_selected {
        background: #5e01c6;
    }
}

.placemark-amount {
    font-weight: 500;
    color: #5e01c6;

    &_selected {
        color: #fff;
    }
}

.placemark-complex {
    font-weight: 500;

    &_selected {
        color: #fff;
    }
}

.yandex-maps {
    width: auto;
    height: 679px;
}

.MuiAutocomplete-input {
    height: 48px;
}

.MuiAutocomplete-inputFocused {
    height: 48px;
}

.router-link {
    text-decoration: none;
    color: inherit;
}
